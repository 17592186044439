.list {
  font-size: var(--text-sm);
  --checkbox-radio-size: 1.25em;
  --checkbox-radio-translate-y: 0.25em;
  --gap: var(--space-xxxs);
  opacity: 1;
  flex-direction: column;
  display: flex;
  margin-bottom: calc(-1 * var(--gap));
  margin-left: calc(-1 * var(--gap));
}

.item {
  position: relative;
  display: flex;
  align-items: baseline;
  margin-bottom: var(--gap);
  margin-left: var(--gap);
}
