@import 'mixins';
@import 'breakpoints';

.promotedProduct {
  width: 100%;
  position: relative;
  margin: 0;

  button {
    max-width: 90%;
  }

  @include breakpoint(sm) {
    display: flex;
    flex-direction: row-reverse;
  }
}

.promotedProductImage {
  height: 430px;
  background-position: center;

  @include breakpoint(sm) {
    height: auto;
  }
}

.price {
  font-size: var(--text-md);
  color: var(--color-black);
  font-weight: 500;
}
