@import 'mixins';
@import 'breakpoints';

.button {
    --anim-menu-btn-size: 40px;
    // 🍔 icon
    --anim-menu-btn-icon-size: 28px;
    --anim-menu-btn-icon-stroke: 2px;

    display: flex;
    justify-content: left;
    align-items: center;
    width: var(--anim-menu-btn-size);
    height: var(--anim-menu-btn-size);
    color: inherit;
    flex-basis: 0;
    flex-grow: 1;
    cursor: pointer;

    &:focus,
    &.active {
        outline: none;
    }

}

.icon {
    position: relative;
    display: block;
    font-size: var(--anim-menu-btn-icon-size);
    width: 1em;
    height: var(--anim-menu-btn-icon-stroke);
    color: inherit;
    background-image: linear-gradient(currentColor, currentColor);
    background-repeat: no-repeat;
    transform: scale(1);
    background-size: 100% 100%;
    will-change: transform, background-size;
    transition: transform .2s, background-size .2s;

    &::before, 
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: inherit;
        border-radius: inherit;
        will-change: inherit;
        transition: inherit;
    }

    &::before { // line top
        transform: translateY(-0.25em) rotate(0);
    }

    &::after { // line bottom
        transform: translateY(0.25em) rotate(0);
    }

    &.open {
        background-size: 0% 100%;

        &::before {
            transform: translateY(0) rotate(45deg);
        }

        &::after {
            transform: translateY(0) rotate(-45deg);
        }
    }
}

@include breakpoint(md) {
    .button{
        display: none;
    }
  }
  
