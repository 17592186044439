@import 'mixins';
@import 'breakpoints';

.card {
  display: flex;
  flex-direction: column;
}

.imageLink {
  display: block;
  padding: .3em .6em .1em .6em;
  background-color: white;
}

.content {
  padding: var(--space-sm) var(--component-padding);
  text-align: left;
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  font-size: var(--text-base);
  text-decoration: none;
  color: var(--color-black);
}

.excerpt {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: var(--space-xs) 0 var(--space-sm);
  color: var(--color-contrast-medium);
  font-size: var(--text-sm);
}

.price {
  font-size: var(--text-md);
  color: var(--color-black);
  font-weight: 500;
}

.vat {
  font-size: var(--text-xs);
  margin-top: var(--space-xxs);
  color: var(--color-grey-blue);
}

.brokenImageHide {
  flex-grow: 1;
  padding: 35%;
}