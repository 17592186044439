@import 'breakpoints';

.slider {
  width: 100%;
  height: 24px;
}

.track {
  height: 4px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--color-contrast-low);
}

.trackOne {
}
.trackTwo {
  background-color: var(--color-accent);
}
.trackThree {
}

.thumb {
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  background-color: var(--color-accent);
  color: var(--color-white);
  border-radius: 50%;
  box-shadow: var(--shadow-xs);
}

.labels {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: var(--text-sm);
  color: var(--color-contrast-medium);
  margin-bottom: var(--space-xxs);
}
