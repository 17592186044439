@import 'mixins';
@import 'breakpoints';

.hero {
  background-color: var(--color-accent);
  color: var(--color-white);
}

.image {
  height: 296px;
}

.children {
  order: 2;
  h1 {
    color: var(--color-secondary);
  }
}

@include breakpoint(md) {
  .image {
    height: auto;
  }
}
