.dropdownContainer {
  min-height: 28rem;
  height: auto;
  background-color: var(--color-accent);
  padding: 3rem 0;
  color: #fff;
  position: absolute;
  width: 100%;
  left: 0;
  top: 4.7em;
}

.subCategory {
  color: #fff;
  text-decoration: none;
  padding: 1.25em;
}

.categoryTitle {
  background-color: #808a94;
}

.subItems {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  padding: 0.5em;
}