.breadcrumbs {
  font-size: var(--text-sm);
}

.list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.breadcrumb {
  display: inline-block; // flex fallback
  display: inline-flex;
  align-items: center;
  border: none;
}

.breadcrumb a {
  font-weight: 400;
  cursor: pointer;
  text-decoration: none;

  &:hover:not(.current a){
    text-decoration: underline;
  }
}

.current a {
  color: var(--color-contrast-high);
  cursor: default;
  text-decoration: none;
}

.icon {
  margin-left: var(--space-xxxs);
  color: var(--color-contrast-medium);
}
