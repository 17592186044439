.hover\:reduce-opacity {
  opacity: 1;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}

.hover\:scale {
  transition: transform 0.8s var(--ease-out-back);

  &:hover {
    transform: scale(1.1);
  }
}

.hover\:elevate {
  box-shadow: var(--shadow-sm);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: var(--shadow-md);
  }
}

// text styles
.link-subtle {
  color: var(--color-primary);
  cursor: pointer;
  font-weight: 400;
  text-decoration: underline;
  @include fontSmooth;
  transition: all 0.2s ease;

  &:hover {
    color: var(--color-primary-lighter);
    transition: none;
  }
}

.link-secondary {
  color: var(--color-black);
  font-weight: 400;
  text-decoration: underline;

  &:hover {
    color: var(--color-contrast-medium);
  }
}

.table-head {
  color: var(--color-contrast-medium);
  font-weight: 400;

  &:hover {
    color: var(--color-black);
  }

  &:active {
    color: var(--color-black);
  }
}

:root {
  --shadow-sm:  0 0.3px 0.4px rgba(216, 216, 216, 0.5),
                0 0.9px 1.5px rgba(216, 216, 216, 0.5), 
                0 3.5px 6px rgba(216, 216, 216, 0.5);

  --radius: 30px; // border radius base size
}