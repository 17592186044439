@import 'mixins';

.choiceBtns {
  display: flex;
  flex-direction: column;
}

.fallback {
  @include srHide; // visible only to screen readers
}

.choiceBtn {
  --choiceBtn-border-width: 2px;
  --choiceBtn-border-radius: 0;
  --choiceBtn-align-items: center; // set vertical alignment between custom input and content [center, start, end]
  // custom input
  --choiceBtn-input-size: 20px; // custom input size
  --choiceBtn-input-icon-size: 16px; // icon size
  --choiceBtn-input-border-width: 2px; // custom input border width
  --choiceBtn-input-margin-right: var(--space-sm); // gap between custom input and content
  --choiceBtn-input-translate-y: 0em; // (optional) translate-y input to align it with the text

  position: relative;
  background-color: var(--color-white);
  border-width: var(--choiceBtn-border-width);
  border-style: solid;
  border-color: var(--color-contrast-low);
  border-radius: var(--choiceBtn-border-radius);
  user-select: none;
  cursor: pointer;
  transition: 0.2s;

  // &:hover {
  //   border-color: lightness(var(--color-contrast-low), 0.85);
  // }

  // &.choiceBtn--focus,
  // &.checked,
  // &:active {
  //   border-color: var(--color-primary);
  // }

  &.choiceBtn--focus,
  &:active {
    box-shadow: 0 0 0 2px alpha(var(--color-primary), 0.2);
  }
}

.grid {
  display: block; // fallback
  display: grid;
  grid-template-columns: var(--choiceBtn-input-size) 1fr;
  grid-gap: var(--choiceBtn-input-margin-right);
  align-items: var(--choiceBtn-align-items);
}

// custom input
.input {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-bg);
  width: var(--choiceBtn-input-size);
  height: var(--choiceBtn-input-size);
  border-width: var(--choiceBtn-input-border-width);
  border-style: solid;
  border-color: var(--color-contrast-low);
  position: relative;
  top: var(--choiceBtn-input-translate-y);

  .icon {
    color: var(--color-white); // icon color
    font-size: var(--choiceBtn-input-icon-size);
  }

  .checked & {
    border-color: var(--color-primary);
    background-color: var(--color-primary);
  }
}

.inputCheckbox {
  border-radius: var(--radius-sm);

  .icon > * {
    transition: stroke-dashoffset 0.3s;
    stroke-dasharray: 18;
    stroke-dashoffset: 18;
  }

  .checked & .icon > * {
    stroke-dasharray: 18;
    stroke-dashoffset: 0;
  }
}

.inputRadio {
  border-radius: 50%;

  .icon {
    transition: transform 0.3s var(--ease-out-back);
    transform: scale(0);
  }

  .checked & .icon {
    transform: scale(1);
  }
}
