@import '~codyhouse-framework/main/assets/css/base/mixins';

.pagination {
}

.list {
  width: 100%;

  > li {
    display: inline-block; // flex fallback
  }

  // > *:first-child {
  //   margin-right: auto;
  // }

  // > *:last-child {
  //   margin-left: auto;
  // }
}

.item {
  display: inline-block; // flex fallback
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
  padding-left: calc(1.355 * var(--space-xs));
  padding-right: calc(1.355 * var(--space-xs));
  border-radius: var(--radius-md);
  text-decoration: none;
  height: 100%;
  color: var(--color-contrast-higher);
  transition: 0.2s;
  @include fontSmooth;
  position: relative;

  &:hover:not(.selected):not(.ellipsis) {
    background-color: var(--color-white);
    color: var(--color-black);
    box-shadow: var(--shadow-sm);
  }

  &:active {
    transform: translateY(2px);
  }
}

.selected {
  background-color: var(--color-white);
  color: var(--color-black);
  box-shadow: var(--shadow-sm);
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.ellipsis {
  opacity: 0.5;
  pointer-events: none;
}
