@import 'mixins';
@import 'breakpoints';

.logo {
  width: 152px; 
  height: 46px; 
  display: block;
}

.link {
  color: var(--color-black);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.footer p {
  line-height: 1.4;
  padding-top: 0.2rem;
}
.footer strong {
  line-height: 1.4;
}

.logoFooter {
  width: 256px;
  height: auto; 
}