@import 'mixins';

.alert {
  padding: var(--space-xs) var(--space-sm);
  border-radius: var(--radius-md);
  color: var(--color-contrast-higher);
  border: 1px solid transparent;
}

.button {
  @include reset;
  display: inline-block; // flex fallback
  flex-shrink: 0;
  margin-left: var(--space-sm);
  cursor: pointer;

  .icon {
    display: block;
  }
}

// themes
.info {
  background-color: alpha(var(--color-contrast-medium), 0.2);
  border-color: alpha(var(--color-contrast-darker), 0.2);
}

.success {
  background-color: alpha(var(--color-success), 0.2);
}

.error {
  background-color: #ffe3e6;
  border-color: rgba(158, 28, 35, 0.2);
}

.warning {
  background-color: alpha(var(--color-warning), 0.2);
}
