@import 'mixins';

.root {
  overflow: hidden;
  border-bottom: 2px solid var(--color-contrast-low);
}

.inner {
  position: relative;
  overflow: auto;
  
  &::-webkit-scrollbar { // custom scrollbar style
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track { // progress bar
    background-color: var(--color-contrast-lower);
  }

  &::-webkit-scrollbar-thumb { // handle
    background-color: alpha(var(--color-contrast-higher), 0.9);
    border-radius: 50em;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-contrast-higher);
  }
}

.table {
  width: 100%;
}

.header {
  .cell {
    background-color: var(--color-bg);
    box-shadow: 0 2px 0 var(--color-contrast-low);
  }
}

.body {
  .row {
    border-bottom: 1px solid var(--color-contrast-lower);

    &:last-child {
      border-bottom: none;
    }
  }
}

.cell { // standard cell
  padding: var(--space-md) var(--space-xs);
}

.cell--th { // header cell
  padding-top: var(--space-xs);
  padding-bottom: var(--space-xs);
}

.cell--sort { // header cell with sorting option
  user-select: none;

  &:hover, &:focus-within {
    background-color: var(--color-contrast-lower);
  }
  
  &:hover {
    cursor: pointer;
  }
}

.cell--focus {
  background-color: alpha(var(--color-primary), 0.15);
}

.sort-icon { // sorting icon indicator
  .arrow-up, .arrow-down {
    fill: alpha(var(--color-contrast-higher), 0.3);
  }
}

.cell--asc .sort-icon .arrow-up,
.cell--desc .sort-icon .arrow-down {
  fill: var(--color-contrast-higher);
}

// --sticky-header
.sticky {
  position: relative;
  z-index: 1;

  .inner {
    max-height: 480px;
  }

  .header {
    .cell {
      position: sticky;
      top: 0;
      z-index: 2;
    }
  }
}