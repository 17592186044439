@import 'breakpoints';

.container {
  display: block;
  width: 100%;
  position: relative;
}

.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;

  + label {
    display: inline-block; // fallback
    display: inline-flex;
    align-items: baseline;
    line-height: var(--body-line-height);
    user-select: none;
    cursor: pointer;
    font-size: var(--text-md);
    width: 100%;
  }

  + label::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    flex-shrink: 0;
    width: 1.25em;
    height: 1.25em;
    background-color: var(--color-bg);
    border-width: 1px;
    border-color: var(--color-contrast-low);
    border-style: solid;
    margin-right: var(--space-xxs);
    background-repeat: no-repeat;
    background-position: center;
    transition: transform 0.2s, border 0.2s;
    position: relative;
    top: 0.21875em;
    border-radius: 0.185em;
  }

  &:checked + label::before {
    background-color: var(--color-accent);
    box-shadow: none;
    border-color: var(--color-accent);
    transition: transform 0.2s;
  }

  &:checked + label::before {
    background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PGcgY2xhc3M9J25jLWljb24td3JhcHBlcicgc3Ryb2tlLXdpZHRoPScyJyBmaWxsPScjZmZmZmZmJyBzdHJva2U9JyNmZmZmZmYnPjxwb2x5bGluZSBmaWxsPSdub25lJyBzdHJva2U9JyNmZmZmZmYnIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLWxpbmVqb2luPSdyb3VuZCcgc3Ryb2tlLW1pdGVybGltaXQ9JzEwJyBwb2ludHM9JzEsOSA1LDEzIDE1LDMgJyBkYXRhLWNhcD0nYnV0dCc+PC9wb2x5bGluZT48L2c+PC9zdmc+);
    background-size: 12px;
  }

  &:active + label::before {
    transform: scale(0.8);
    transition: transform 0.2s;
  }

  &:focus + label::before {
    border-color: var(--color-accent);
    // box-shadow: 0 0 0 3px hsla(45, 52%, 59%, 0.2);
  }

  // Disabled state
  &:disabled + label::before,
  &:checked:disabled + label::before {
    background-color: var(--color-contrast-low);
    border-color: var(--color-contrast-low);
  }

  &:disabled + label {
    color: var(--color-contrast-medium);
  }
}

.label {
  display: block;
}

.suffix {
  margin-left: auto;
  color: var(--color-contrast-medium);
}

@include breakpoint(md) {
  .checkbox + label {
    font-size: var(--text-base-size);
  }
}
