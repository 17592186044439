@import 'breakpoints';

.link {
  display: block;
  display: flex;
  align-items: center; // align icon with text
  color: var(--color-white);
  text-decoration: none;
  padding: var(--space-md);
  font-family: var(--font-secondary);
  border-bottom: 1px solid transparent;
  height: 100%;

  &:hover,
  &[aria-current='page'] {
    border-bottom: 2px solid var(--color-black);
    font-weight: 500;
  }

  .icon {
    flex-shrink: 0;
  }
}

.link:last-child {
  border: none;
  font-weight: normal;
}

@include breakpoint(md) {
  .item {
    display: inline-block; // fallback
    position: relative;
    border-bottom: none;
    margin-right: var(--space-xs); // margin between nav items

    &:last-child {
      margin-right: 0;
    }
  }

  .link {
    padding: var(--space-xxxs) var(--space-xxs);
    color: var(--color-contrast-high);
  }

  .linkIcon {
    span {
      display: none;
    }
  }
}