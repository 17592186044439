@import 'mixins';
@import 'breakpoints';

.card {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--color-sand-light);
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-sand-light);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.imageLink {
  display: block;
  padding: .3em .6em .1em .6em;
  background-color: white;
}

.content {
  padding: var(--space-sm) var(--component-padding);
  text-align: left;
  color: var(--color-black);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.title {
  font-size: var(--text-base);
  text-decoration: none;
  color: var(--color-black);  
  word-break: break-word;
}

.excerpt {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: var(--space-xs) 0 var(--space-sm);
  color: var(--color-contrast-medium);
  font-size: var(--text-sm);
}

.price {
  font-size: var(--text-md);
  color: var(--color-black);
  font-weight: 500;
}

.vat {
  font-size: var(--text-xs);
  margin-top: var(--space-xxs);
  color: var(--color-grey-blue);
}

.flags {
  position: absolute;
  top: -10px;
  left: var(--component-padding);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}