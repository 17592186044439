@import 'breakpoints';
@import 'mixins';

.sliderCon {
  height: 500px;
  display: flex;
  flex-direction: row-reverse;
}

.productImageWrapper {
  padding: var(--component-padding);
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
  background-color: #f8f8f8;
}

.verticleSlider {
  display: block !important;
}

.imageBox > div {
  padding: var(--component-padding);
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
  height: 100px;
  width: 100px;
  background-color: #f8f8f8;
}

@media screen and (max-width: 687px) {
  .verticleSlider {
    display: none !important;
  }
  .sliderCon {
    height: auto;
  }
}
