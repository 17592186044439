@import 'breakpoints';

.container {
  background: var(--color-sand);
}

.inner {
  text-align: center;
  font-size: var(--text-sm);
  padding-top: var(--space-sm);
  padding-bottom: var(--space-sm);
  display: flex;
  align-items: center;
  justify-content: center;
}

@include breakpoint(md) {
  .inner {
    text-align: center;
    font-size: var(--text-xs);
    padding-top: var(--space-xs);
    padding-bottom: var(--space-xs);
    justify-content: space-between;
  }
}
