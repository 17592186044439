.avatar {
  --avatar-size: 1em;
  font-size: var(--avatar-size);
  position: relative;
}

.figure {
  width: var(--avatar-size);
  height: 0;
  padding-bottom: var(--avatar-size);
  overflow: hidden;
}

.img,
.placeholder {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  border-radius: inherit;
}

.placeholder {
  width: var(--avatar-size);
  height: var(--avatar-size);
}

// sizes
.xs {
  --avatar-size: 0.75em; // 12px
}

.sm {
  --avatar-size: 1em; // 16px
}

.md {
  --avatar-size: 1.125em; // 18px
}

.lg {
  --avatar-size: 1.25em; // 20px
}

.xl {
  --avatar-size: 1.5em; // 24px
}
