.root {
  display: inline-block;
  display: inline-flex;
  text-align: center;
  align-items: center;
  letter-spacing: 0.25em;

  & span {
    background-color: var(--color-contrast-medium);
    border-radius: 50%;
    height: 2px;
    width: 2px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    margin: 0 2px;

    &:nth-of-type(2) {
      animation-delay: 0.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
