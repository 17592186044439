body {
  --aa-primary-color-rgb: 0, 0, 0;
  --aa-font-size: var(--text-sm);
  --aa-icon-color-rgb: 0,0,0;

  // Input
  --aa-input-background-color-rgb: 238, 242, 246;
  --aa-input-border-color-alpha: 0;

  // Muted colors
  --aa-muted-color-alpha: 0.5;
  --aa-muted-color-rgb: 0,0,0;

  // Panel
  --aa-panel-border-color-alpha: 0;
  --aa-selected-color-rgb: 237,234,227;
  --aa-selected-color-alpha: 0.7;

  .aa-Panel {
    z-index: 1;
  }
}
