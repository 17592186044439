:root {
  // font family
  --font-primary: aktiv-grotesk, sans-serif;

  // font size
  --text-base-size: 1em; // body font-size
  --text-scale-ratio: 1.2; // multiplier used to generate the type scale values 👇

  // line-height
  --body-line-height: 1.6;
  --heading-line-height: 1.2;

  // capital letters - used in combo with the lhCrop mixin
  --font-primary-capital-letter: 0.875;

  // unit - don't modify unless you want to change the typography unit (e.g., from Em to Rem units)
  --text-unit: 1em; // if not Em units → --text-unit: var(--text-base-size);
}

:root,
* {
  // type scale
  --text-xs: calc((var(--text-unit) / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio));
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --text-xxxxl: calc(var(--text-xxxl) * var(--text-scale-ratio));
}

body {
  font-family: var(--font-primary);
  font-weight: 400;
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-primary);
  font-weight: 500;
}

// font family
.font-primary {
  font-family: var(--font-primary);
}

// color
.color-secondary-darker {
  color: alpha(var(--color-secondary-darker), var(--color-o, 1));
}
.color-secondary-dark {
  color: alpha(var(--color-secondary-dark), var(--color-o, 1));
}
.color-secondary {
  color: alpha(var(--color-secondary), var(--color-o, 1));
}
.color-secondary-light {
  color: alpha(var(--color-secondary-light), var(--color-o, 1));
}
.color-secondary-lighter {
  color: alpha(var(--color-secondary-lighter), var(--color-o, 1));
}
.color-brand-darker {
  color: alpha(var(--color-brand-darker), var(--color-o, 1));
}
.color-brand-dark {
  color: alpha(var(--color-brand-dark), var(--color-o, 1));
}
.color-brand {
  color: alpha(var(--color-brand), var(--color-o, 1));
}
.color-brand-light {
  color: alpha(var(--color-brand-light), var(--color-o, 1));
}
.color-brand-lighter {
  color: alpha(var(--color-brand-lighter), var(--color-o, 1));
}
.color-sand-darker {
  color: alpha(var(--color-sand-darker), var(--color-o, 1));
}
.color-sand-dark {
  color: alpha(var(--color-sand-dark), var(--color-o, 1));
}
.color-sand {
  color: alpha(var(--color-sand), var(--color-o, 1));
}
.color-sand-light {
  color: alpha(var(--color-sand-light), var(--color-o, 1));
}
.color-sand-lighter {
  color: alpha(var(--color-sand-lighter), var(--color-o, 1));
}
.color-frost-darker {
  color: alpha(var(--color-frost-darker), var(--color-o, 1));
}
.color-frost-dark {
  color: alpha(var(--color-frost-dark), var(--color-o, 1));
}
.color-frost {
  color: alpha(var(--color-frost), var(--color-o, 1));
}
.color-frost-light {
  color: alpha(var(--color-frost-light), var(--color-o, 1));
}
.color-frost-lighter {
  color: alpha(var(--color-frost-lighter), var(--color-o, 1));
}
.color-grey-blue-darker {
  color: alpha(var(--color-grey-blue-darker), var(--color-o, 1));
}
.color-grey-blue-dark {
  color: alpha(var(--color-grey-blue-dark), var(--color-o, 1));
}
.color-grey-blue {
  color: alpha(var(--color-grey-blue), var(--color-o, 1));
}
.color-grey-blue-light {
  color: alpha(var(--color-grey-blue-light), var(--color-o, 1));
}
.color-grey-blue-lighter {
  color: alpha(var(--color-grey-blue-lighter), var(--color-o, 1));
}
