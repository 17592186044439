.quote {
  font-family: var(--font-secondary);
}

.quote p::before {
  content: '“';
}

.quote p::after {
  content: '”';
}
