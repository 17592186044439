@import 'mixins';
@import 'breakpoints';

.root {
  --sidebar-width: 480px;
  position: fixed;
  overflow: hidden;
  z-index: var(--zindex-overlay);
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  &:focus {
    outline: none;
  }
}

.inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: alpha(var(--color-contrast-higher), 0.6);
}

.drawerWrap {
  position: absolute;
  top: 0;
  bottom: 0;
  outline: none;
  display: flex;
  max-width: 80vw;

  // @include breakpoint(sm) {
  //   padding-left: 2rem;
  // }
}

.drawer {
  height: 100%;
  width: 100vw;
  max-width: 100vw;
  background-color: var(--color-white);

  @include breakpoint(md) {
    max-width: var(--sidebar-width);
    width: var(--sidebar-width);
  }
}

.drawerInner {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch; // smooth scroll on iOS
}
