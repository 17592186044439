@import 'mixins';

.accordionGroup {
  --choice-accordion-border-width: 2px; // accordion border width
  --choice-accordion-border-radius: 0; // accordion border radius

  border-width: var(--choice-accordion-border-width);
  border-color: var(--color-contrast-low);
  border-style: solid;
  border-radius: var(--choice-accordion-border-radius);
  overflow: hidden;
}

.item {
  // custom input
  --choice-accordion-input-size: 20px; // custom input size
  --choice-accordion-input-icon-size: 16px; // icon size
  --choice-accordion-input-border-width: 2px; // custom input border width
  --choice-accordion-input-margin-right: var(--space-xxs); // gap between custom input and label
  --choice-accordion-input-background: var(--color-white);

  position: relative;
}

.btn {
  display: block; // fallback
  display: grid;
  grid-template-columns: var(--choice-accordion-input-size) 1fr;
  grid-gap: var(--choice-accordion-input-margin-right);
  align-items: center;
  cursor: pointer;
  background-color: var(--choice-accordion-input-background);
  border-top-width: var(--choice-accordion-border-width);
  border-style: solid;
  border-color: var(--color-contrast-low);
}

.item:first-child .btn {
  border-top: none;
}

.panel {
  position: relative;
  background-color: var(--color-contrast-lower);
  overflow: hidden;
  will-change: height;
  transform: translateZ(0px);

  &::before {
    // panel top border
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--choice-accordion-border-width);
    background-color: var(--color-contrast-low);
    pointer-events: none;
  }
}

// custom input
.input {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-bg);
  width: var(--choice-accordion-input-size);
  height: var(--choice-accordion-input-size);
  border-width: var(--choice-accordion-input-border-width);
  border-style: solid;
  border-color: var(--color-contrast-low);
  transition: 0.2s;

  .icon {
    color: var(--color-white); // icon color
    font-size: var(--choice-accordion-input-icon-size);
  }

  .btn:hover & {
    border-color: var(--color-contrast-medium);
  }

  .btnChecked &,
  .btnFocus &,
  .btnChecked:hover &,
  .btnFocus:hover & {
    border-color: var(--color-primary);
  }

  .btnChecked & {
    background-color: var(--color-primary);
  }

  .btnFocus & {
    box-shadow: 0 0 0 3px alpha(var(--color-primary), 0.2);
  }
}

// Not used but can be if implemented
.inputCheckbox {
  border-radius: var(--radius-sm);

  .icon > * {
    transition: stroke-dashoffset 0.3s;
    stroke-dasharray: 18;
    stroke-dashoffset: 18;
  }

  .btnChecked & .icon > * {
    stroke-dasharray: 18;
    stroke-dashoffset: 0;
  }
}

.inputRadio {
  border-radius: 50%;

  .icon {
    transition: transform 0.3s var(--ease-out-back);
    transform: scale(0);
  }

  .btnChecked & .icon {
    transform: scale(1);
  }
}

.fallback {
  @include srHide; // visible only to screen readers
}
