.select {
  position: relative;
  --select-icon-size: 1em;
  --select-icon-right-margin: 0; // icon margin right
  --select-text-icon-gap: var(--space-xxxs); // gap between text and icon
}

.input {
  width: 100%;
  height: 100%;
  padding: 0;
  padding-right: calc(var(--select-icon-size) + var(--select-text-icon-gap)) !important;
  appearance: none;
  border: 0 none;
  background: transparent;
  padding: 1em;

  &:focus,
  &:active {
    outline: none;
  }
}

.icon {
  width: var(--select-icon-size);
  height: var(--select-icon-size);
  pointer-events: none;
  position: absolute;
  right: var(--select-icon-right-margin);
  top: 50%;
  transform: translateY(-50%);
}
