@import 'mixins';
@import 'breakpoints';

.header {
  --header-height: 60px; // header height on small screens
  --header-logo-width: 104px;
  --text-unit: 16px;
  font-size: var(--text-unit);
  height: var(--header-height);
  width: 100%;
  z-index: var(--zindex-header);
  transition: background 0.3s;
  background: var(--color-tertiary);
  border-bottom: 1px solid var(--color-sand);

  @include breakpoint(md) {
    --header-height: 75px;
  }
}

.container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.logoWrapper {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.logo {
  display: block;
  width: var(--header-logo-width);
  flex-shrink: 0;
  text-decoration: none;

  svg,
  img {
    display: block;
  }
}

.actions {
  text-align: right;
}

.actionItem {
  position: relative;
}

.action {
  cursor: pointer;
}

a.action {
  text-decoration: none;
  font-size: var(--text-sm);
  color: var(--color-contrast-higher);
}

.bagCount {
  font-size: var(--text-xs);
  background: var(--color-brand);
  color: var(--color-white);
  height: var(--icon-xs);
  width: var(--icon-xs);
  position: absolute;
  top: -5px;
  right: -14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  transform: translate(-20%, 20%);
  pointer-events: none;
}

.desktop {
  display: none;
}

@include breakpoint(md) {
  .header {
    height: var(--header-height);
  }

  // .container {
  //   position: relative;
  // }

  .desktop {
    display: block;
  }

  .logoWrapper {
    justify-content: left;
  }
}
