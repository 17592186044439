@import 'breakpoints';
@import 'mixins';

.notice {
  position: fixed;
  bottom: var(--space-md);
  width: 100%;
  pointer-events: none;
  z-index: var(--zindex-fixed-element);
}

.banner {
  pointer-events: auto;
  max-width: var(--max-width-xxxs);
  background: var(--color-white);
  padding: var(--space-sm);
  box-shadow: var(--shadow-md);
  border-radius: none;
}

.close-btn {
  display: flex;
  width: 2em;
  height: 2em;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: alpha(var(--color-contrast-higher), 0.85);
  color: var(--color-bg);
  transition: 0.2s;

  &:hover {
    background-color: var(--color-contrast-higher);
  }
}

.hide {
  opacity: 0;
  visibility: hidden;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .notice {
    transition: opacity 0.3s, visibility 0s 0.3s;
  }

  .banner {
    transition: transform 0.3s;
    padding: var(--space-md);
    max-width: var(--max-width-xxs);
  }

  .hide {
    .banner {
      transform: translateY(20px);
    }
  }
}
