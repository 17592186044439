@import 'mixins';

.skeleton {
  position: relative;
  background-color: alpha(var(--color-contrast-higher), 0.1);
  overflow: hidden;
  --ske-animation-duration: 1s;
  --ske-radius: 0;

  &::after {
    // animated background effect
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      90deg,
      alpha(var(--color-contrast-higher), 0),
      alpha(var(--color-contrast-higher), 0.1),
      alpha(var(--color-contrast-higher), 0)
    );
    background-repeat: no-repeat;
    background-size: 500px 100%;
    background-position: -500px 0;
    will-change: background-position;
    animation: ske-background var(--ske-animation-duration) infinite;
  }
}

@keyframes ske-background {
  from {
    background-position: -500px 0;
  }

  to {
    background-position: calc(100% + 500px) 0;
  }
}

// --circle
.skeleton--circle {
  height: 0;
  padding-bottom: 100%;
  border-radius: 50%;
  clip-path: circle(50% at 50% 50%);
}

// --rectangle
.skeleton--rect,
.skeleton--square {
  border-radius: var(--ske-radius);
  clip-path: inset(0% 0% 0% 0% round var(--ske-radius));
}

.skeleton--square {
  height: 0;
  padding-bottom: calc(100% / (var(--ske-aspect-ratio)));
}

.skeleton--rect-16\:9 {
  --ske-aspect-ratio: 16/9;
}

.skeleton--rect-4\:3 {
  --ske-aspect-ratio: 4/3;
}

.skeleton--square,
.skeleton--rect-1\:1 {
  --ske-aspect-ratio: 1/1;
}

// --text
.skeleton--text {
  height: 1em;
  border-radius: var(--ske-radius);
  clip-path: inset(0% 0% 0% 0% round var(--ske-radius));
}
