.accordion {
  --accordion-border-width: 1px;
  --accordion-border-color: var(--color-contrast-low);
  --accordion-icon-size: 1em;
  --accordion-icon-stroke-width: 2px;

  list-style: none !important;
  margin: 0;
  padding: 0;
}

.accordionItem {
  margin-bottom: var(--space-xs);
  border-bottom: 2px solid var(--color-sand-light);
}
.accordionItem:last-child {
  border-bottom: none;
}

.trigger {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  cursor: pointer;
  padding: var(--space-sm) var(--space-sm);
  background-color: transparent;
  appearance: none;
  border: 0 none;
  border-bottom: 1px solid transparent;
  &:focus,
  &:active {
    outline: none;
  }
}

.title {
  font-size: 1rem;
  font-weight: 600;
}

.subtitle {
  margin-top: 2px;
  color: var(--color-contrast-medium);
  font-size: var(--text-sm);
}

.panel {
}

.panelInner {
  padding: var(--space-sm) var(--space-sm);
}

.iconArrow {
  color: var(--color-contrast-high);

  .iconGroup {
    transition: transform 0.3s var(--ease-out);
    will-change: transform;
    transform-origin: 8px 8px;

    > * {
      stroke-dasharray: 17;
      transform: translateY(3px);
      transform-origin: 8px 8px;
      transition: transform 0.3s, stroke-dashoffset 0.3s;
      transition-timing-function: var(--ease-out);
    }

    > *:first-child {
      stroke-dashoffset: 8.5;
    }

    > *:last-child {
      stroke-dashoffset: 8.5;
    }
  }
}

.open {
  .trigger {
    border-bottom: 1px solid var(--color-bg);
  }

  .iconGroup {
    transform: rotate(-90deg);

    > *:first-child,
    *:last-child {
      stroke-dashoffset: 0;
      transform: translateY(0px);
    }
  }
}
