@import 'mixins';
@import 'breakpoints';

.root {
  position: fixed;
  z-index: var(--zindex-overlay);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: alpha(var(--color-contrast-higher), 0.6);
}

.modal {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
  width: 100%;
  max-width: calc(100vw - 32px);
  background-color: var(--color-white);
  box-shadow: var(--shadow-sm);
  position: relative;
}

.modal:focus {
  outline: none;
}

.closeButton {
  display: flex;
  flex-shrink: 0;
  border-radius: 50%;
  transition: 0.2s;
  position: absolute;
  top: var(--space-xs);
  right: var(--space-xs);

  .icon {
    color: inherit; // icon color
    display: block;
    margin: auto;
  }
}