.select {
  --select-icon-size: 1em;
  --select-icon-right-margin: var(--space-sm); // icon margin right
  --select-text-icon-gap: var(--space-xxxs); // gap between text and icon
  position: relative;
}

.input {
  width: 100%;
  height: 100%;
  padding-right: calc(
    var(--select-icon-size) + var(--select-icon-right-margin) + var(--select-text-icon-gap)
  ) !important;
}

.icon {
  width: var(--select-icon-size);
  height: var(--select-icon-size);
  pointer-events: none;
  position: absolute;
  right: var(--select-icon-right-margin);
  top: 50%;
  transform: translateY(-50%);
}
