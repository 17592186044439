@import 'breakpoints';

.metaData {
  padding: var(--space-sm) 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  border-color: var(--color-contrast-low);
  border-width: 1px 0 1px 0;
  border-style: solid;
  font-size: var(--text-sm);
  color: var(--color-contrast-high);
}

.accessorieText {
  font-size: 14px;
}

@include breakpoint(md) {
  // show the navigation past the medium breakpoint
  .specifications {
    column-count: 2;
    column-gap: var(--space-lg);
  }
}

.productImageWrapper {
  padding: var(--component-padding);
  box-shadow: rgb(0 0 0 / 5%) 0px 1px 2px 0px;
  background-color: #f8f8f8;
}