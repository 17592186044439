// Override Codyhouse Settings
$media-wrapper-aspect-ratios: ((1 1), (2 3), (4 3), (4 5));

// import the basic style of the codyhouse framework
@import '~codyhouse-framework/main/assets/css/base';

// add your custom style
@import 'codyhouse/colors';
@import 'codyhouse/spacing';
@import 'codyhouse/shared-styles';
@import 'codyhouse/typography';
@import 'codyhouse/icons';
@import 'codyhouse/buttons';
@import 'codyhouse/forms';
@import 'codyhouse/util';
@import 'components/circle-loader.scss';
@import 'components/algolia-autocomplete.scss';

html {
  scroll-behavior: smooth;
}

mark {
  background-color: var(--color-secondary-lighter);
}

.btn {
  padding-top: calc(var(--btn-padding-y) + 2px);
}

.form-control {
  padding-bottom: calc(var(--form-control-padding-y) - 1px);
}
