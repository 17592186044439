@import 'mixins';

.button {
  --numberInput-btn-width: 28px;
  // custom increment buttons
  display: inline-block; // flex fallback
  display: flex;
}

.inputWrap {
  display: flex;
  align-items: center;
  font-size: 0.875em;
  background-color: var(--color-frost);
  border-radius: var(--radius-lg);
  padding: var(--space-xxs) var(--space-xxs);
}

// basic style
.input::-webkit-inner-spin-button,
.input::-webkit-outer-spin-button {
  // hide native increment on webkit
  display: none;
}

.input {
  // hide native increment on Firefox
  -moz-appearance: textfield;
  display: block;
  margin: 0 var(--space-xxs);
  padding: 0;
  width: 4em;
  order: 1;
  text-align: center;
  font-weight: 400;
  font-size: var(--text-base-size);
  background-color: transparent;
}

.button {
  @include reset;
  width: var(--numberInput-btn-width);
  height: var(--numberInput-btn-width);
  border-radius: 50%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background-color: var(--color-grey-blue);
  transition: transform 0.2s, border 0.2s;
  transform-origin: center;
  position: relative;
  color: var(--color-white);

  &:hover {
    border-color: var(--color-accent);
    background-color: var(--color-accent);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px hsla(45, 52%, 59%, 0.2);

    .icon {
      color: var(--color-white);
    }
  }

  &:active {
    transform: scale(0.9);
    transition: transform 0.2s;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.icon {
  font-size: 12px; // icon size
  width: 1em;
  height: 1em;
  transform-origin: center;
}

.buttonPlus {
  order: 2;
}

.buttonMinus {
  order: 0;
}

.buttonMinusDeactivated {
  order: 0;
  background-color: alpha(var(--color-grey-blue-lighter), 0.5);

  &:hover,
  &:focus{
    cursor: not-allowed;
    background-color: var(--color-grey-blue-lighter);
  }
}