:root {
  --form-control-font-size: 0.8em;
  --form-control-padding-x: var(--space-sm);
  --form-control-padding-y: var(--space-sm);
  --form-control-radius: 0;
}

.form-control {
  background: var(--color-frost);
  border-bottom: 1px solid transparent;
  font-weight: 300;
  line-height: 1.2;
  transition: all 0.2s ease;

  &::placeholder {
    opacity: 1;
    color: var(--color-contrast-medium);
  }

  &:focus {
    border-bottom: 1px solid var(--color-accent);
    box-shadow: none;
    outline: none;
  }
}

.form-control--disabled,
.form-control[disabled],
.form-control[readonly] {
  cursor: not-allowed;
}

.form-control[aria-invalid='true'],
.form-control--error {
  border-color: var(--color-error);

  &:focus {
    box-shadow: 0px 0px 0px 2px alpha(var(--color-error), 0.2);
  }
}

.form-legend {
  font-family: var(--font-primary);
  font-size: var(--text-md);
  font-weight: 400;
}

.form-label {
  font-size: var(--text-sm);
  font-weight: 400;
}
