@import 'mixins';
@import 'breakpoints';

// >>> style affecting both all (collapsed + static) versions 👇
.root {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

.control { // control button - all versions
  width: 100%;
  padding: var(--space-sm);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.controlText { // text inside control button - all versions
  position: relative;

  > * {
    display: inline-block;
    transition: opacity .4s, transform .4s var(--ease-out);
  }

  > *:last-child {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);
  }
}

.iconArrow { // arrow icon 
  .iconGroup{
    will-change: transform;
    transform-origin: 8px 8px;
    transition: transform .3s var(--ease-out);

    > * {
      transform-origin: 8px 8px;
      stroke-dasharray: 17;
      transform: translateY(3px);
      transition: transform .3s, stroke-dashoffset .3s;
      transition-timing-function: var(--ease-out);
    }

    > *:first-child {
      stroke-dashoffset: 8.5;
    }

    > *:last-child {
      stroke-dashoffset: 8.5;
    }
  }
}

.list:not(:last-child) { // list - all versions
  margin-bottom: var(--space-sm);
}

.label { // label/separator - all versions
  margin-bottom: var(--space-xxxxs);

  > * {
    color: var(--color-contrast-medium);
    font-size: var(--text-sm);
  }
}

.link { // link - all versions
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--color-contrast-high);
  transition: .2s;

  &--active {
    text-decoration: underline;
  }

  &[aria-current="page"] {
    background-color: var(--color-contrast-higher);
    color: var(--color-bg);
  }
}

.linkText { // text inside links - all versions
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: var(--space-xxxs);
}

.counter { // notification counter - all versions
  display: inline-flex;
  background-color: alpha(var(--color-contrast-higher), 0.15);
  font-size: var(--text-xs);
  padding: var(--space-xxxxs) var(--space-xs);
  border-radius: 50em;
  margin-left: auto;
}
// <<< end style affecting all versions

// >>> collapsed version only (mobile) 👇
.root:not(.static) {
  background-color: var(--color-bg);
  box-shadow: var(--shadow-sm);
  transition: .3s;

  &:hover {
    box-shadow: var(--shadow-md);
    background-color: alpha(var(--color-contrast-higher), 0.05);
  }

  .nav { // navigation - collapsed version only
    display: none;
    margin: var(--space-xxs) 0;
  }

  .label { // label - collapsed version only
    padding-left: var(--space-sm);
  }

  .link { // link - collapsed version only
    padding: var(--space-xxs) var(--space-sm);
  }

  &.expanded {
    .controlText {
      > *:first-child {
        transform: translateY(10px);
        opacity: 0;
        pointer-events: none;
      }

      > *:last-child {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0);
      }
    }

    .nav {
      display: block;
      animation: sidenav-entry-animation .4s var(--ease-out);
    }

    .iconArrow .iconGroup{ // animate arrow icon
      transform: rotate(-90deg);
  
      > *:first-child, *:last-child {
        stroke-dashoffset: 0;
        transform: translateY(0px);
      }
    }
  }
}
// <<< end collapsed version

// >>> static version only (desktop) 👇
.static {

  .control { // control - static version only
    display: none;
  }

  .link { // link - static version only
    padding: var(--space-xs) 0;
    border-radius: 0;

    &[aria-current="page"] {
      box-shadow: var(--shadow-sm);
    }
  }

  .list > li:not(:last-child) .link {
    border-bottom: 1px solid var(--color-contrast-lower);
  }

}
// <<< end static version

@keyframes sidenav-entry-animation {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}