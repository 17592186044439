.root {
  display: flex;
  height: auto;
}

.list {
  display: flex;
  overflow: auto hidden;
  width: 100%;
  max-width: 100%;
  flex-direction: row;
  padding: 0;
  margin: 0;
  list-style: none;
  outline: 0;
  scrollbar-width: none;
}

.tab {
  display: flex;
  width: auto;
  height: 40px;
  padding: 0;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  padding: 0 var(--space-sm);

  &:not(:last-child) {
    margin-right: var(--space-md);
  }
}

.content {
  background-color: var(--color-sand);
}

.tabButton {
  cursor: pointer;
}

.selected {
  // border-bottom: 2px solid var(--color-primary);
  border-bottom: solid 2px #da291c;
  font-weight: 500;
}

.disabled {
}

.content {
  padding: var(--component-padding);
}

.contentHidden {
  display: none;
}
